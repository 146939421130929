import {
  AmbientLight,
  Box3,
  Cache,
  DirectionalLight,
  HemisphereLight,
  LinearEncoding,
  LoaderUtils,
  LoadingManager,
  PMREMGenerator,
  PerspectiveCamera,
  REVISION,
  Scene,
  SkeletonHelper,
  Vector3,
  WebGLRenderer,
  sRGBEncoding,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import { GUI } from 'dat.gui';

import { environments } from '../assets/environment/index.js';

const DEFAULT_CAMERA = '[default]';

const MANAGER = new LoadingManager();
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`
const DRACO_LOADER = new DRACOLoader( MANAGER ).setDecoderPath( `${THREE_PATH}/examples/js/libs/draco/gltf/` );
const KTX2_LOADER = new KTX2Loader( MANAGER ).setTranscoderPath( `${THREE_PATH}/examples/js/libs/basis/` );

// glTF texture types. `envMap` is deliberately omitted, as it's used internally
// by the loader but not part of the glTF format.
const MAP_NAMES = [
  'map',
  'aoMap',
  'emissiveMap',
  'glossinessMap',
  'metalnessMap',
  'normalMap',
  'roughnessMap',
  'specularMap',
];

const Preset = {ASSET_GENERATOR: 'assetgenerator'};

Cache.enabled = true;

export class Viewer {

  constructor (el, options) {
    this.el = el;
    this.options = options;

    this.lights = [];
    this.content = null;
    this.mixer = null;
    // this.gui = null;

    this.state = {
      environment: options.preset === Preset.ASSET_GENERATOR
        ? environments.find((e) => e.id === 'footprint-court').name
        : environments[1].name,
      background: false,
      camera: DEFAULT_CAMERA,

      // Lights
      addLights: true,
      exposure: 1.0,
      textureEncoding: 'sRGB',
      ambientIntensity: 0.65,
      ambientColor: 0xFFFFFF,
      directIntensity: 2.9,
      directColor: 0xFFFFFF,
    };

    this.prevTime = 0;

    this.scene = new Scene();

    const fov = options.preset === Preset.ASSET_GENERATOR
      ? 0.8 * 180 / Math.PI
      : 60;
    this.defaultCamera = new PerspectiveCamera( fov, el.clientWidth / el.clientHeight, 0.01, 1000 );
    this.scene.add( this.defaultCamera );

    try {
      this.renderer = window.renderer = new WebGLRenderer({antialias: true, alpha: true});
    } catch(e) {
      console.error(e);
      return false;
    }
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.setClearColor( 0x000000, 0 );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( el.clientWidth, el.clientHeight );

    this.pmremGenerator = new PMREMGenerator( this.renderer );
    this.pmremGenerator.compileEquirectangularShader();

    this.el.appendChild(this.renderer.domElement);

    this.morphFolder = null;
    this.morphCtrls = [];

    this.addGUI();
    // if (options.kiosk) this.gui.close();

    this.animate = this.animate.bind(this);
    requestAnimationFrame( this.animate );
    window.addEventListener('resize', this.resize.bind(this), false);
  }

  animate (time) {
    requestAnimationFrame( this.animate );

    const dt = (time - this.prevTime) / 1000;

    this.mixer && this.mixer.update(dt);
    this.render();

    this.prevTime = time;
  }

  render () {
    this.renderer.render( this.scene, this.defaultCamera );
  }

  resize () {
    const {clientHeight, clientWidth} = this.el.parentElement;

    this.defaultCamera.aspect = clientWidth / clientHeight;
    this.defaultCamera.updateProjectionMatrix();
    this.renderer.setSize(clientWidth, clientHeight);
  }

  load ( url, rootPath, assetMap ) {
    const baseURL = LoaderUtils.extractUrlBase(url);

    // Load.
    return new Promise((resolve, reject) => {

      // Intercept and override relative URLs.
      MANAGER.setURLModifier((url, path) => {

        // URIs in a glTF file may be escaped, or not. Assume that assetMap is
        // from an un-escaped source, and decode all URIs before lookups.
        // See: https://github.com/donmccurdy/three-gltf-viewer/issues/146
        const normalizedURL = rootPath + decodeURI(url)
          .replace(baseURL, '')
          .replace(/^(\.?\/)/, '');

        if (assetMap.has(normalizedURL)) {
          const blob = assetMap.get(normalizedURL);
          const blobURL = URL.createObjectURL(blob);
          blobURLs.push(blobURL);
          return blobURL;
        }

        return (path || '') + url;

      });

      const loader = new GLTFLoader( MANAGER )
        .setCrossOrigin('anonymous')
        .setDRACOLoader( DRACO_LOADER )
        .setKTX2Loader( KTX2_LOADER.detectSupport( this.renderer ) )
        .setMeshoptDecoder( MeshoptDecoder );

      const blobURLs = [];

      loader.load(url, (gltf) => {

        const scene = gltf.scene || gltf.scenes[0];

        if (!scene) {
          // Valid, but not supported by this viewer.
          throw new Error(
            'This model contains no scene, and cannot be viewed here. However,'
            + ' it may contain individual 3D resources.'
          );
        }

        this.setContent(scene);

        blobURLs.forEach(URL.revokeObjectURL);

        resolve(gltf);
      }, undefined, reject);

    });

  }

  /**
   * @param {THREE.Object3D} object
   */
  setContent ( object ) {
    this.clear();

    const box = new Box3().setFromObject(object);
    const size = box.getSize(new Vector3()).length();
    const center = box.getCenter(new Vector3());


    object.position.x += (object.position.x - center.x);
    object.position.y += (object.position.y - center.y);
    object.position.z += (object.position.z - center.z);
    this.defaultCamera.near = size / 100;
    this.defaultCamera.far = size * 100;
    this.defaultCamera.zoom = 1.2;
    this.defaultCamera.updateProjectionMatrix();

    this.defaultCamera.position.x = "0.004609680639701262";
    this.defaultCamera.position.y = "0.7624680980915302";
    this.defaultCamera.position.z = "0.4381771489383654";

    this.defaultCamera.rotation.x = 0;
    this.defaultCamera.rotation.y = 0;
    this.defaultCamera.rotation.z = 0;


    this.scene.add(object);
    this.content = object;

    this.state.addLights = true;

    this.content.traverse((node) => {
      if (node.isLight) {
        this.state.addLights = false;
      } else if (node.isMesh) {
        node.material.depthWrite = !node.material.transparent;
      }
    });


    this.updateLights();
    this.updateGUI();
    this.updateTextureEncoding();
    this.updateDisplay();

    window.content = this.content;
    console.info('[glTF Viewer] THREE.Scene exported as `window.content`.');
  }

  updateTextureEncoding () {
    const encoding = this.state.textureEncoding === 'sRGB'
      ? sRGBEncoding
      : LinearEncoding;
    traverseMaterials(this.content, (material) => {
      if (material.map) material.map.encoding = encoding;
      if (material.emissiveMap) material.emissiveMap.encoding = encoding;
      if (material.map || material.emissiveMap) material.needsUpdate = true;
    });
  }

  updateLights () {
    const state = this.state;
    const lights = this.lights;

    if (state.addLights && !lights.length) {
      this.addLights();
    } else if (!state.addLights && lights.length) {
      this.removeLights();
    }

    this.renderer.toneMappingExposure = state.exposure;

    if (lights.length === 2) {
      lights[0].intensity = state.ambientIntensity;
      lights[0].color.setHex(state.ambientColor);
      lights[1].intensity = state.directIntensity;
      lights[1].color.setHex(state.directColor);
    }
  }

  addLights () {
    const state = this.state;

    if (this.options.preset === Preset.ASSET_GENERATOR) {
      const hemiLight = new HemisphereLight();
      hemiLight.name = 'hemi_light';
      this.scene.add(hemiLight);
      this.lights.push(hemiLight);
      return;
    }

    const light1  = new AmbientLight(state.ambientColor, state.ambientIntensity);
    light1.name = 'ambient_light';
    this.defaultCamera.add( light1 );

    const light2  = new DirectionalLight(state.directColor, state.directIntensity);
    light2.position.set(0.5, 0, 0.866); // ~60º
    light2.name = 'main_light';
    this.defaultCamera.add( light2 );

    this.lights.push(light1, light2);
  }

  removeLights () {
    this.lights.forEach((light) => light.parent.remove(light));
    this.lights.length = 0;
  }

  getCubeMapTexture ( environment ) {
    const { path } = environment;

    // no envmap
    if ( ! path ) return Promise.resolve( { envMap: null } );

    return new Promise( ( resolve, reject ) => {
      new RGBELoader()
        .load( path, ( texture ) => {
          const envMap = this.pmremGenerator.fromEquirectangular( texture ).texture;
          this.pmremGenerator.dispose();
          resolve( { envMap } );
        }, undefined, reject );
    });
  }

  updateDisplay () {

    traverseMaterials(this.content, (material) => {
      material.wireframe = this.state.wireframe;
    });

    window.sk = []
    this.content.traverse((node) => {
      if (node.isMesh && node.skeleton) {
        const helper = new SkeletonHelper(node.skeleton.bones[0].parent);
        // helper.material.linewidth = 3;
        // this.scene.add(helper);
        // this.skeletonHelpers.push(helper);
        window.sk = helper;
      }
    });
    window.bones = {};
    window.sk.bones.forEach((b) => {
      window.bones[b.name] = b;
    })
  }


  addGUI () {
    const gui = this.gui = new GUI({autoPlace: false, width: 260, hideable: true});

    // Lighting controls.
    const lightFolder = gui.addFolder('Lighting');
    const encodingCtrl = lightFolder.add(this.state, 'textureEncoding', ['sRGB', 'Linear']);
    encodingCtrl.onChange(() => this.updateTextureEncoding());
    lightFolder.add(this.renderer, 'outputEncoding', {sRGB: sRGBEncoding, Linear: LinearEncoding})
      .onChange(() => {
        this.renderer.outputEncoding = Number(this.renderer.outputEncoding);
        traverseMaterials(this.content, (material) => {
          material.needsUpdate = true;
        });
      });

    [
      lightFolder.add(this.state, 'ambientIntensity', 0, 2),
      lightFolder.add(this.state, 'directIntensity', 0, 4),
    ].forEach((ctrl) => ctrl.onChange(() => this.updateLights()));

    // Morph target controls.
    this.morphFolder = gui.addFolder('Morph Targets');
    this.morphFolder.domElement.style.display = 'none';
  }

  updateGUI () {
    this.morphCtrls.forEach((ctrl) => ctrl.remove());
    this.morphCtrls.length = 0;
    this.morphFolder.domElement.style.display = 'none';

    const morphMeshes = [];
    this.content.traverse((node) => {
      if (node.isMesh && node.morphTargetInfluences) {
        morphMeshes.push(node);
      }
    });

    window.morphs = {}
    window.meshes = []
    if (morphMeshes.length) {
      this.morphFolder.domElement.style.display = '';
      morphMeshes.forEach((mesh) => {
        if (mesh.morphTargetInfluences.length) {
          const nameCtrl = this.morphFolder.add({name: mesh.name || 'Untitled'}, 'name');
          this.morphCtrls.push(nameCtrl);
        }
        for (let i = 0; i < mesh.morphTargetInfluences.length; i++) {
          const ctrl = this.morphFolder.add(mesh.morphTargetInfluences, i, 0, 1, 0.01).listen();
          Object.keys(mesh.morphTargetDictionary).forEach((key) => {
            if (key && mesh.morphTargetDictionary[key] === i) {
              ctrl.name(key);
              ctrl.mesh = mesh;

              if (window.morphs[key]) {
                window.morphs[key].push(ctrl);
              } else {
                window.morphs[key] = [ctrl];
              }

            };

          });
          this.morphCtrls.push(ctrl);
        }
      });
    }
  }

  clear () {
    if ( !this.content ) return;
    this.scene.remove( this.content );

    // dispose geometry
    this.content.traverse((node) => {
      if ( !node.isMesh ) return;
      node.geometry.dispose();
    } );

    // dispose textures
    traverseMaterials( this.content, (material) => {
      MAP_NAMES.forEach( (map) => {
        if (material[ map ]) material[ map ].dispose();
      } );
    } );
  }

};

function traverseMaterials (object, callback) {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    materials.forEach(callback);
  });
}

// ========================================================
// ======================== CUSTOM ========================
// ========================================================

function animFace(keys, targetVal=1, duration=25, steps=0.1, delay=0) {
  /* Oui */

  function execute(key) {
    let val = window.morphs[key][0].getValue();
    let isDecrement =val > targetVal;
    if (val == targetVal) {
      return false;
    }

    let interval = null;
    interval = setInterval(() => {
      val += (isDecrement ? -1*steps : steps);

      if(
        (isDecrement && (val < targetVal))
        || (!isDecrement && (val > targetVal))
      ) {
        val = targetVal;
        clearInterval(interval)
      }

      window.morphs[key].forEach((c) => c.setValue(val));
    }, duration);
  }

  setTimeout(() => {
    keys.forEach((k) => {
      execute(k);
    });
  }, delay);
}
window.animFace = animFace

function animBone(bone, axe, targetVal=1, duration=25, steps=0.08, delay=10) {
  /* Oui */

  setTimeout(() => {
    let val = window.bones[bone].rotation[axe];
    let isDecrement = val > targetVal;
    if (val == targetVal) {
      return false;
    }

    let interval = null;
    interval = setInterval(() => {
      val += (isDecrement ? -1*steps : steps);
      if(
        (isDecrement && (val <= targetVal))
        || (!isDecrement && (val >= targetVal))
      ) {
        val = targetVal;
        clearInterval(interval)
      }

      window.bones[bone].rotation[axe] = val;
    }, duration);


  }, delay);
}
window.animBone = animBone

/*
anim(["mouthOpen", "mouthSmile"], 1, 25)
anim(["mouthOpen", "mouthSmile", "eyeLookInLeft", "eyeLookOutRight", "eyeLookUpLeft", "eyeLookUpRight"], 0, 25, 1000)
anim(["mouthOpen", "mouthSmile", "eyeLookInLeft", "eyeLookOutRight"], 1, 25, 2000);
anim(["eyeLookUpLeft", "eyeLookUpRight"], 0.5, 25, 3000);
*/